<template>
    <div class="sector">
        <SanackBar
            :activated="snackActivated"
            :success="snackSuccess"
            :message="snackMessage"
            @closeSnackBar="closeSnack()" />
        <div class="form">
            <div class="form-head">
                <h1 class="head-title">{{ titles.sector }}</h1>

                <v-chip class="ma-2" color="primary" v-if="isEditionMode">
                    <v-icon left> mdi-database </v-icon>
                    id: {{ reason.id }}
                </v-chip>
            </div>

            <div class="form-container">
                <div class="form-fields">
                    <div class="field-box field-emotion">
                        <label>Emotion</label>
                        <v-select
                            v-model="reason.emotionId"
                            :items="emotions"
                            chips
                            clearable
                            item-text="name"
                            item-value="id"
                            filled
                            rounded>
                        </v-select>
                    </div>

                    <div class="field-box field-description">
                        <label>Descrição</label>
                        <v-text-field
                            v-model="reason.description"
                            ref="description"
                            type="name"
                            filled
                            rounded
                            dense>
                        </v-text-field>
                    </div>

                    <div class="field-box field-submit">
                        <SubmitFormButton
                            :title="titles.submitButton"
                            :disabled="formSubmited"
                            @submitForm="submitForm()" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';
    import SanackBar from '@/components/snack/SanackBar.vue';
    import SubmitFormButton from '@/components/SubmitFormButton.vue';
    import { filterPropertiesInObject } from '@/utils/filterProperties';
    import { parseDTO } from '@/utils/parseDTO';

    export default {
        name: 'FormReason',
        components: { SanackBar, SubmitFormButton },
        data() {
            return {
                isEditionMode: false,
                snackActivated: false,
                snackSuccess: false,
                snackMessage: null,
                formSubmited: false,
                emotions: [],
                reason: {
                    id: null,
                    emotionId: null,
                    description: null
                },
                titles: {
                    sector: null,
                    submitButton: null
                }
            };
        },
        mounted() {
            this.checkIsEditionMode();
        },
        computed: {
            ...mapGetters({
                reasonStore: 'getDataReason'
            })
        },
        methods: {
            async checkIsEditionMode() {
                await this.serviceLoadingEmotions();

                if (this.reasonStore.isEditionMode) {
                    this.titles.sector = 'Editar motivo';
                    this.titles.submitButton = 'Atualizar';

                    this.isEditionMode = true;

                    this.reason.id = this.reasonStore.id;

                    await this.serviceLoadingReason();
                } else {
                    this.titles.sector = 'Criar motivo';
                    this.titles.submitButton = 'Criar';
                }
            },
            activeSnack(success, message) {
                this.snackActivated = true;
                this.snackSuccess = success;
                this.snackMessage = message;
            },
            closeSnack() {
                this.snackActivated = false;
            },
            goToAfterSubmitForm() {
                this.$router.push({ name: 'reasonsAdmin' });
            },
            validateSubmitForm() {
                const snack = (message) => this.activeSnack(false, message);

                const validateReasonDescription = (description) => {
                    if (!description) {
                        snack('Preencha o campo descrição!');
                        return true;
                    }

                    if (description.length < 4) {
                        snack('A descrição deve conter no mínimo 4 caracteres!');
                        return true;
                    }

                    if (description.length > 256) {
                        snack('A descrição deve conter no máximo 256 caracteres!');
                        return true;
                    }

                    return false;
                };

                const errors = [validateReasonDescription(this.reason.description)];

                return !errors.some((e) => e);
            },
            dataParseEmotions(emotions) {
                const propertiesToFilter = ['id', 'name'];

                return emotions.map((e) =>
                    filterPropertiesInObject(e, propertiesToFilter)
                );
            },
            dataParseReason(reason) {
                const propertiesToFilter = ['id', 'emotionId', 'description', 'slug'];

                return filterPropertiesInObject(reason, propertiesToFilter);
            },
            async serviceLoadingEmotions() {
                this.$store.commit('setGlobalLoading', true);

                this.$service.http['admin']
                    .filterEmotions()
                    .then((result) => {
                        const records = result.data.docs;

                        this.emotions = this.dataParseEmotions(records);
                    })
                    .catch((e) => {
                        console.error(e);
                    })
                    .finally((_) => {
                        this.$store.commit('setGlobalLoading', false);
                    });
            },
            async serviceLoadingReason() {
                this.$store.commit('setGlobalLoading', true);

                const reasonId = this.reasonStore.id;

                this.$service.http['admin']
                    .getReason(reasonId)
                    .then((result) => {
                        const record = result.data.doc;

                        this.reason = this.dataParseReason(record);
                    })
                    .catch((e) => {
                        console.error(e);
                    })
                    .finally((_) => {
                        this.$store.commit('setGlobalLoading', false);
                    });
            },
            async serviceUpdateReason() {
                const dataParsed = parseDTO(this.reason);

                await this.$service.http['admin']
                    .updateReason(this.reason.id, dataParsed)
                    .then((_) => {
                        this.activeSnack(true, 'Motivo atualizado!');

                        setTimeout(() => {
                            this.goToAfterSubmitForm();
                        }, 3000);
                    })
                    .catch((e) => {
                        console.error(e);

                        this.activeSnack(
                            false,
                            'Ocorreu um erro no processamento do endpoint!'
                        );
                    });
            },
            async serviceCreateReason() {
                const dataParsed = parseDTO(this.reason);

                this.$service.http['admin']
                    .createReason(dataParsed)
                    .then((_) => {
                        this.activeSnack(true, 'Motivo cadastrado!');

                        setTimeout(() => {
                            this.goToAfterSubmitForm();
                        }, 2000);
                    })
                    .catch((e) => {
                        console.error(e);

                        this.activeSnack(
                            false,
                            'Ocorreu um erro no processamento do endpoint!'
                        );
                    })
                    .finally((_) => {
                        this.formSubmited = false;

                        this.$store.commit('setGlobalLoading', false);
                    });
            },
            async submitForm() {
                if (!this.validateSubmitForm()) return;

                this.$store.commit('setGlobalLoading', true);

                this.formSubmited = true;

                if (this.isEditionMode) {
                    await this.serviceUpdateReason();
                } else {
                    await this.serviceCreateReason();
                }

                this.formSubmited = false;

                this.$store.commit('setGlobalLoading', false);
            }
        }
    };
</script>

<style scoped>
    .sector {
        position: relative;
        width: 350px;
    }

    .sector-head-title {
        font-size: 26px;
    }

    .form-container {
        margin-top: 20px;
    }
    /* .field-name {
        margin-top: 20px;
    } */

    .field-submit {
        margin-top: 10px;
    }

    .field-date-hour {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    .field-date-hour .field-date,
    .field-date-hour .field-hour {
        width: 48%;
    }
</style>
